<template>

    <div class="loading">

    </div>

</template>


<script>

    import {mapActions,mapGetters} from 'vuex';

    import router from '@/router'

    export default{
        name : 'cerrar-sesion',
        mounted(){
            this.requestLogout(this.usertype).then(() => {
                router.push({name : 'login'})
            })
        },
        computed : {
            ...mapGetters({
                usertype : 'getUserType'
            })
        },
        methods : {
            ...mapActions(['requestLogout']),
        }
    }
</script>