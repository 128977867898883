<template>
    <div class="wrapper -registro">

        <div class="top container row-between">

            <router-link to="/" class="logo-container">
                <img class="logo" src="/img/logo-uc3m-alumni.png" alt="Uc3m Alumni">
            </router-link>

            <div class="row-end">
                <p>¿Ya eres mentor o mentee?</p>
                <router-link :to="$t('links.login')" class="btn -skull-blue">Iniciar sesión</router-link>
            </div>

        </div>

        <div class="contenedor-formulario">

            <h1 v-if="data.type == 'profesor'">Nuevo Mentor/a</h1>
            <h1 v-if="data.type == 'mentee'">Nuevo Mentee</h1>

            <template v-if="paso == 1">



                <legend>Datos personales</legend>


                <template v-if="alumnidata">


                    <ValidationObserver ref="form" v-slot="{failed,valid}">

                        <form @submit.prevent="validate" class="formulario" :valid="valid" :failed="failed">

                            <div class="formulario row-center">
                                <p class="tip">*Campos obligatorios</p>
                                <div class="contenedor-input -middle readonly">
                                    <label for="dni">DNI, NIE o pasaporte* <popup-info :data="'ayuda-login'">
                                        </popup-info></label>
                                    <input type="text" v-model="alumnidata.dni" name="dni" id="dni" readonly
                                        title="Campo no editable">
                                </div>

                                <div class="contenedor-input -middle readonly">
                                    <label for="nia">NIA<popup-info :data="'ayuda-nia'">
                                        </popup-info></label>
                                    <input type="text" v-model="alumnidata.nia" name="nia" id="nia" readonly
                                        title="Campo no editable">
                                </div>

                                <div class="contenedor-input -middle readonly">
                                    <label for="nombre">Nombre</label>
                                    <input type="text" v-model="alumnidata.name" name="nombre" id="nombre" readonly
                                        title="Campo no editable">
                                </div>

                                <div class="contenedor-input -middle readonly">
                                    <label for="nombre">Primer apellido</label>
                                    <input type="text" v-model="alumnidata.surname" name="nombre" id="nombre" readonly
                                        title="Campo no editable">
                                </div>

                                <div class="contenedor-input -middle readonly">
                                    <label for="nombre">Segundo apellido</label>
                                    <input type="text" v-model="alumnidata.surname_2" name="nombre" id="nombre" readonly
                                        title="Campo no editable">
                                </div>

                                <div class="contenedor-input -middle readonly">
                                    <label for="birthday_date">Año de nacimiento</label>
                                    <input type="text" v-model="alumnidata.birthday_date" name="birthday_date"
                                        id="birthday_date" readonly title="Campo no editable">
                                </div>

                                <div class="contenedor-input -middle">
                                    <ValidationProvider rules="required|email"
                                        v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                        <label for="nombre">Email personal*</label>
                                        <input type="text" v-model="data.email" name="nombre" id="nombre"
                                            :class="classes">
                                    </ValidationProvider>
                                </div>

                                <div class="contenedor-input -middle readonly">
                                    <label for="nombre">Email UC3M</label>
                                    <input type="text" v-model="alumnidata.email_internal" name="nombre" id="nombre"
                                        readonly title="Campo no editable">
                                </div>

                                <div class="contenedor-input -middle">
                                    <ValidationProvider rules="required|numeric"
                                        v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                        <label for="nombre">Teléfono*</label>
                                        <input type="tel" v-model="data.phone" name="nombre" id="nombre"
                                            :class="classes">
                                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="contenedor-input -middle">
                                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                        <label for="pais">País de residencia*</label>

                                        <select :class="classes" v-model="data.country" name="pais" id="pais">
                                            <option value="">País de residencia</option>
                                            <option :value="pais" :key="key" v-for="(pais,key) in domains.countries">
                                                {{pais}}</option>
                                        </select>

                                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>


                                <div class="contenedor-input -middle" v-show="data.country != 'España'">
                                    <ValidationProvider :rules="(data.country != 'España') ? 'required' : ''" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                        <label for="provincia">Provincia de residencia*</label>
                                        <input type="text" :class="classes"
                                            v-model="data.province" name="provincia" id="provincia">
                                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
        
                                <div class="contenedor-input -middle" v-show="data.country == 'España'">
                                    <ValidationProvider :rules="(data.country == 'España') ? 'required' : ''" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                        <label for="provinciaselect">Provincia de residencia*</label>
                                        <select  :class="classes" v-model="data.province" name="provinciaselect" id="provinciaselect">
                                            <option value="">Provincia de residencia</option>
                                            <option :value="provincia" :key="key" v-for="(provincia,key) in domains.provinces">
                                                {{provincia}}</option>
                                        </select>
                                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
        




                                <div class="contenedor-boton row-between">
                                    <a>Paso 1 de 2</a>
                                    <a class="btn -skull-white" @click="next">Siguiente</a>
                                </div>

                            </div>

                        </form>

                    </ValidationObserver>

                </template>

            </template>

            <template v-if="paso == 2">

                <legend>Datos académicos</legend>
                <template v-if="alumnidata && alumnidata.academic.length != 0">



                    <div class="formulario row-center" v-if="alumnidata.academic">


                        <div class="contenedor-input readonly">
                            <label for="nombre">Titulación</label>
                            <input type="text" v-model="alumnidata.academic[0].name" name="nombre" id="nombre" readonly
                                title="Campo no editable">
                        </div>


                    </div>

                </template>

                <template v-else>
                    <div class="formulario row-center">
                        <div class="contenedor-input readonly">
                            <label for="nombre">Titulación</label>
                            <input type="text" value="Sin definir" name="nombre" id="nombre" readonly
                                title="Campo no editable">
                        </div>
                    </div>
                </template>

                <legend>Datos Profesionales</legend>


                <ValidationObserver ref="form" v-slot="{failed,valid}">

                    <form @submit.prevent="validate" class="formulario" :valid="valid" :failed="failed">

                        <div class="contenedor-input" v-if="domains.experience">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="empresa">Experiencia laboral* <popup-info :data="'experiencia-total'">
                                    </popup-info></label>
                                <select :class="classes" v-model="data.profesional_xp" name="empresa" id="empresa">
                                    <option value="">Selecciona experiencia laboral</option>
                                    <option :key="key" v-for="(n,key) in domains.experience" :value="key">{{n}}</option>
                                </select>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input" v-if="data.profesional_xp != 0">
                            <ValidationProvider :rules="(data.type == 'profesor') ? 'required' : ''"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="empresa">Empresa actual{{(data.type == 'profesor') ? '*' : ''}}</label>
                                <input type="text" :class="classes" v-model="data.profesional_current_company"
                                    name="empresa" id="empresa">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input" v-if="data.profesional_xp != 0">
                            <ValidationProvider :rules="(data.type == 'profesor') ? 'required' : ''"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="cargo">Cargo actual{{(data.type == 'profesor') ? '*' : ''}}</label>
                                <input type="text" :class="classes" v-model="data.profesional_current" name="cargo"
                                    id="cargo">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input">
                            <label for="empresa">Perfil Linkedin</label>
                            <input type="text" v-model="data.profesional_linkedin" name="linkedin" id="linkedin">
                        </div>







                        <legend>Preferencias de mentoría</legend>




                        <div class="contenedor-input -middle" v-if="domains.preferences">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="preferenciamentoria">Modalidad de mentoría*</label>
                                <select :class="classes" v-model="data.preferences_participation"
                                    name="preferenciamentoria" id="preferenciamentoria">
                                    <option value="">Selecciona preferencia</option>
                                    <option :key="key" v-for="(n,key) in domains.preferences" :value="key">{{n}}
                                    </option>
                                </select>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-multiselect -middle -white" v-if="domains.area">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="area">Áreas profesionales*</label>
                                <multiselect :class="classes" :multiple="true" track-by="text" label="text"
                                    v-model="data.area" :options="domains.area" :searchable="true"
                                    :close-on-select="true" :show-labels="false"
                                    placeholder="Seleccione uno o varias áreas">
                                </multiselect>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="contenedor-multiselect -middle -white" v-if="sectores">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="area">Sectores profesionales*</label>
                                <multiselect :class="classes" :multiple="true" :track-by="'text'" :label="'text'"
                                    v-model="data.sector" :options="sectores" :searchable="true" :close-on-select="true"
                                    :show-labels="false" placeholder="Seleccione uno o varios sectores">
                                </multiselect>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-multiselect -middle -white" v-if="domains.language">
                            <label for="">Idiomas en mentorías</label>
                            <multiselect :multiple="true" track-by="text" label="text" v-model="data.language"
                                :options="domains.language" :searchable="true" :close-on-select="true"
                                :show-labels="false" placeholder="Seleccione uno o varios idiomas">
                            </multiselect>
                        </div>




                        <template v-if="data.type == 'profesor'">

                            <div class="contenedor-input">
                                <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                    <label for="resume">Breve reseña profesional*</label>
                                    <textarea :class="classes" v-model="data.profesional_resumen" name="resume"
                                        id="resume"></textarea>
                                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>



                        </template>

                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="resume">Motivación para la participación*</label>
                                <textarea :class="classes" v-model="data.preferences_motivation" name="resume"
                                    id="resume"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>



                        <div class="contenedor-check">
                            <ValidationProvider :rules="{ required: { allowFalse: false } }"
                                v-slot="{ errors,ariaInput,ariaMsg, classes }">
                                <input :class="classes" id="politica" name="politica" v-model="data.politica"
                                    type="checkbox" placeholder="politica" v-bind="ariaInput">
                                <label for="politica">Declaro haber entendido la información facilitada y consiento el
                                    tratamiento que se efectuará de mis datos de carácter personal con la finalidad de
                                    inscribirme en el programa.</label>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-check" v-if="data.type == 'profesor'">
                            <input id="visible" name="visible" v-model="data.visible_by_others" type="checkbox"
                                placeholder="visible">

                            <label for="visible" class="width-auto">Acepto formar parte de la Red de Mentores de este
                                programa.</label>
                            <popup-info class="white" :data="'visible-otros-registro'">
                            </popup-info>


                        </div>

                        <div class="texto-legal">
                            <h2>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS.</h2>
                            <p>RESPONSABLE DEL TRATAMIENTO. Universidad Carlos III de Madrid.</p>
                            <p>Contacto DPO: dpd@uc3m.es</p>
                            <p>IDENTIFICACIÓN DEL TRATAMIENTO, MENTORING ALUMNI UC3M</p>
                            <p>FINALIDADES. Gestionar su inscripción y participación como mentor/mente (cada uno en su
                                caso) en el programa Mentoring Alumni UC3M, así como participar en todas las actividades
                                que
                                puedan desarrollarse en el seno de la actividad del programa.</p>
                            <p>EJERCICIO DE DERECHOS. Podrá ejercer sus derechos de acceso, rectificación, supresión,
                                limitación, portabilidad y oposición a su tratamiento, mediante el envío de un correo a
                                la
                                siguiente dirección: dpd@uc3m.es</p>
                            <p>INFORMACIÓN ADICIONAL: Puede consultar la información adicional y detallada sobre nuestra
                                Política de Privacidad en <a href="https://www.uc3m.es/protecciondedatos"
                                    target="_blank">https://www.uc3m.es/protecciondedatos</a></p>
                        </div>

                        <div class="contenedor-boton row-between">
                            <a class="back" @click="paso--">Volver</a>
                            <a class="btn -skull-white" @click="validate" :loading="loading">Registrarme</a>
                        </div>

                    </form>

                </ValidationObserver>

            </template>


        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import router from '@/router';

    export default {
        name: 'registro-saml',
        mounted() {
            this.data.type = (this.$route.params.id == 'mentee') ? 'mentee' : 'profesor';
            this.data.cache_key = this.$route.query.cache_key;

            if (this.data.cache_key) {
                this.requestData();
            }

            this.requestsector(this.data.area).then(r => {
                this.sectores = r;
            })

        },
        data: () => ({
            data: {
                'cache_key': '',
                'profesional_current': '',
                'profesional_current_company': '',
                'profesional_resumen': '',
                'profesional_xp': '',
                'preferences_participation': '',
                'preferences_motivation': '',
                'type': '',
                'title': '',
                'ability': [],
                'observations_other_abilities':null,
                'language': [],
                'email': '',
                'phone': '',
                'country': '',
                'province': '',
                'visible_by_others': false,
                'extra_program_id': null,
                'area' : []
            },
            alumnidata: '',
            loading: false,
            paso: 1,
            sectores : []
        }),
        methods: {
            ...mapActions(['requestRegisterSAML', 'uc3mUserData', 'requestsector']),
            cleanArrays() {
                let clean = ['ability', 'language', 'sector', 'area'];

                clean.forEach(e => {

                    let array = new Array();

                    this.data[e].forEach(v => {
                        array.push(v.id);
                    })

                    this.data[e] = array;

                });

            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;

                        this.cleanArrays();

                        if (this.data.cache_key) {
                            delete this.data.password;
                            delete this.data.password_confirmation;
                        }

                        this.requestRegisterSAML(this.data).then((r) => {
                            this.loading = false;
                            if (r) {
                                setTimeout(() => {
                                    router.push({
                                        name: 'accesosaml',
                                        params: {
                                            id: this.$route.params.id
                                        },
                                        query: {
                                            token: r
                                        }
                                    })
                                }, 3000);
                            }
                        })
                    }
                });
            },

            next() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.paso++;
                    }
                });
            },
            requestData() {
                this.uc3mUserData(this.$route.query.cache_key).then(r => {
                    this.alumnidata = r;
                    if (this.alumnidata.nia) {

                        let zeros = 9 - (String(this.alumnidata.nia).length + 2);
                        let includes = '1';

                        for (let i = 0; i <= zeros; i++) {
                            includes = includes + '0';
                        }

                        this.alumnidata.nia = includes + this.alumnidata.nia;
                    }

                    this.data.email = this.alumnidata.email.trim();
                    this.data.phone = this.alumnidata.phone;
                })
            }
        },
        computed: {
            ...mapGetters({
                domains: 'getDomains'
            })
        },
        watch: {
            'data.area'() {
                this.requestsector(this.data.area).then(r => {
                    this.sectores = r;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .contenedor-radios .radio {
        pointer-events: none;
    }
</style>