<template>
    <div>
        <div class="loading"></div>
    </div>
</template>

<script>

    import {
        mapActions
    } from 'vuex';

    import router from '@/router';

    export default {
        name: 'saml-access',
        mounted() {
            this.setLogged(window.atob(this.$route.query.token)).then(r => {
                if (r) {
                    if (window.sessionStorage.getItem('goto')) {
                        router.push(window.sessionStorage.getItem('goto'))
                        window.sessionStorage.removeItem('goto');
                    } else {
                        router.push({ name: 'home' })
                    }
                }
            })
        },
        methods: {
            ...mapActions(['setLogged'])
        }
    }
</script>